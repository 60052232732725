
const Home = () => {
    return  <div className="component page-background">
  
    <p>
      Välkommen till Amelia och Jonas bröllopssida!<br></br>
      Mera information kommer att tillkomma inom kort!
    </p>

  </div>


  };
  
  export default Home;