const AboutUs = () => {
    return <div className="component page-background">
    Vi heter Amelia och Jonas, vi ær bæst!! ;-) 
    Vi heter Amelia och Jonas, vi ær bæst!! ;-) 
    Vi heter Amelia och Jonas, vi ær bæst!! ;-) 
    Vi heter Amelia och Jonas, vi ær bæst!! ;-) 
    Vi heter Amelia och Jonas, vi ær bæst!! ;-) 
    Vi heter Amelia och Jonas, vi ær bæst!! ;-) 
    </div>;
  };
  
  export default AboutUs;