const Info = () => {
    return <div className="component page-background">
        Vi kommer att befinna oss på kullar och klang i Vænnfors!!!
        Vi kommer att befinna oss på kullar och klang i Vænnfors!!!
        Vi kommer att befinna oss på kullar och klang i Vænnfors!!!
        Vi kommer att befinna oss på kullar och klang i Vænnfors!!!
        Vi kommer att befinna oss på kullar och klang i Vænnfors!!!
        Vi kommer att befinna oss på kullar och klang i Vænnfors!!!
        </div>;
  };
  
  export default Info;