const ToastMaster = () => {
    return <div className="component page-background">
        Toastmasters kommer att vara X och Y skriv till XY@ameliajonas.com før att anmæla tal.
        Toastmasters kommer att vara X och Y skriv till XY@ameliajonas.com før att anmæla tal.
        Toastmasters kommer att vara X och Y skriv till XY@ameliajonas.com før att anmæla tal.
        Toastmasters kommer att vara X och Y skriv till XY@ameliajonas.com før att anmæla tal.
        Toastmasters kommer att vara X och Y skriv till XY@ameliajonas.com før att anmæla tal.
        Toastmasters kommer att vara X och Y skriv till XY@ameliajonas.com før att anmæla tal.
    </div>;
  };
  
  export default ToastMaster;